
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import InputLabel from "./InputLabel.vue";
  import NFTImageCard from "./NFTImageCard.vue";
  import { parseFile } from "@/utils/parseFile";
  import { isMetaMaskConnected } from "@/utils";

  @Component({
    components: {
      LzButton,
      LzTable,
      InputLabel,
      NFTImageCard
    }
  })
  export default class CreateNFTForm extends Vue {
    @Prop({ required: false })
    public formValues!: NFTFormValues;

    @Prop({ required: false })
    public onSubmit!: (formValues: NFTFormValues) => void;

    @Prop({ required: false })
    public readonlyForm!: boolean;

    public attributeType = "";
    public AttributeName = "";

    FIELDS = [
      { id: "name", label: this.$t("NFTs.createNFT.form.attributes.name") },
      {
        id: "type",
        label: this.$t("NFTs.createNFT.form.attributes.type")
      },
      {
        id: "action",
        label: ""
      }
    ];

    async handleOnSubmit() {
      const uploadedImageFile = this.formValues.image;
      const [parsedImage] = await parseFile(uploadedImageFile);

      this.onSubmit({
        ...this.formValues,
        image: parsedImage
      });
    }

    createAttribute() {
      const type = this.attributeType?.trim();
      const name = this.AttributeName?.trim();
      if (!type || !name) return;

      this.formValues.attributes.push({ type, name });

      this.AttributeName = " ";
      this.attributeType = " ";
    }

    deleteProperty(name: string) {
      this.formValues.attributes = this.formValues.attributes.filter(
        attr => attr.name !== name
      );
    }

    mounted() {
      this.checkConnection("eth_requestAccounts");
    }

    async checkConnection(method: EthereumMethod) {
      if (!isMetaMaskConnected(window)) return;

      const [accountAddress] = await window.ethereum.request({ method });
      this.formValues.walletAddress = accountAddress;
    }
  }
