
  import { Component, Vue, Prop } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";

  @Component({
    components: {
      LzButton
    }
  })
  export default class NFTImageCard extends Vue {
    @Prop({ required: true })
    title!: string;

    @Prop({ required: false, default: true })
    imageHeader!: boolean;

    @Prop({ required: true })
    subtitle!: string;

    @Prop({ required: true })
    imageSrc!: string;
  }
