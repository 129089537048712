
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component({})
  export default class InputLabel extends Vue {
    show = false;

    @Prop({ required: true })
    NFTId!: string;

    handleShowPopup() {
      this.show = !this.show;
    }

    viewNFT() {
      this.$router.push({ name: "viewNFT", params: { id: this.NFTId } });
    }

    putOnSale() {
      this.$router.push({ name: "sellNFT", params: { id: this.NFTId } });
    }
  }
