
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import Popup from "./Popup.vue";
  import { apiNFT } from "../api";
  import { namespace } from "vuex-class";
  const auth = namespace("auth");

  @Component({
    components: {
      LzButton,
      LzTable,
      Popup
    }
  })
  export default class NFTsTable extends Vue {
    isLoading = true;
    FIELDS = [
      { id: "name", label: this.$t("NFTs.read.NFTsTable.name") },
      {
        id: "minted_count",
        label: this.$t("NFTs.read.NFTsTable.minted_count")
      },
      {
        id: "min_donation_amount",
        label: this.$t("NFTs.read.NFTsTable.min_donation_amount")
      },
      {
        id: "max_minting_quantity",
        label: this.$t("NFTs.read.NFTsTable.max_minting_quantity")
      }
    ];

    @auth.State("id")
    public ongId!: string;

    nfts: NFT[] = [];

    get alreadyHaveNFT() {
      return this.nfts.length > 0;
    }

    createYourNFT() {
      this.$router.push({ name: "createNFT" });
    }

    async mounted() {
      try {
        this.isLoading = true;
        const { data: nfts } = await apiNFT.getNFTsByOng(this.ongId);

        this.nfts = nfts;
      } catch (err) {
        this.$notify({
          type: "error",
          text: this.$tc("common.error.generic")
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
