
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component({})
  export default class InputLabel extends Vue {
    @Prop({ required: true })
    title!: string;

    @Prop({ required: false })
    subtitle?: string;
  }
