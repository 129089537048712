
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { isMetaMaskConnected } from "@/utils";

  interface CustomWindow extends Window {
    chrome: any;
  }

  @Component({
    components: {
      LzButton
    }
  })
  export default class NFTWallet extends Vue {
    walletAddress = "";

    mounted() {
      this.checkConnection("eth_accounts");
    }

    async checkConnection(method: EthereumMethod) {
      // check if the current browser is chrome
      if (!(window as CustomWindow).chrome) {
        this.$notify({
          title: this.$tc("NFTs.read.wallet.installChrome"),
          text: this.$tc("NFTs.read.wallet.installChromeText"),
          type: "error"
        });
        return;
      }

      if (!isMetaMaskConnected(window)) {
        this.$notify({
          title: this.$tc("NFTs.read.wallet.installMetamask"),
          text: this.$tc("NFTs.read.wallet.installMetamaskText"),
          type: "error"
        });
        return;
      }

      const accounts = await window.ethereum.request({
        method
      });

      this.walletAddress = accounts[0];
    }

    async connectToWallet() {
      await this.checkConnection("eth_requestAccounts");
    }
  }
